<template>
  <div
    :class="[
      'container-toast-simples',
      toast.type || 'info',
      { hasDescripition: !!toast.description },
    ]"
  >
    <font-awesome-icon
      v-if="toast.type === 'error'"
      icon="exclamation-triangle"
      size="lg"
    />
    <font-awesome-icon
      v-else-if="toast.type === 'success'"
      icon="check-circle"
      size="lg"
    />
    <div>
      <strong>{{ toast.title }}</strong>
      <p v-if="toast.description">{{ toast.description }}</p>
    </div>
    <button @click="() => removeToast(toast.id)" type="button">
      <font-awesome-icon icon="times-circle" size="lg" />
    </button>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: ["toast"],
  cumputed: {
    toastType() {
      return this.toast.type || "info";
    },
  },
  data: () => ({ time: null }),
  mounted() {
    this.time = setTimeout(() => {
      this.removeToast(this.toast.id);
    }, 3000);
  },
  methods: {
    ...mapActions("toast", ["removeToast"]),
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timers);
    }
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
div.container-toast-simples {
  width: 300px;

  position: relative;
  padding: 16px 30px 16px 16px;
  border-radius: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);

  display: flex;

  & + div {
    margin-top: 8px;
  }

  &.info {
    background: #ebf8ff;
    color: #3172b7;
  }
  &.success {
    background: #e6fffa;
    color: #2e656a;
  }
  &.error {
    background: #fddede;
    color: #c53030;
  }

  > svg {
    margin: 18px 12px 0 0;
  }

  div {
    flex: 1;

    p {
      margin-top: 4px;
      font-size: 14px;
      opacity: 0.8px;
      line-height: 20px;
    }
  }

  button {
    position: absolute;
    right: 16px;
    top: 19px;
    opacity: 0.6;
    border: 0;
    background: transparent;
    color: inherit;
    cursor: pointer;
  }

  &.hasDescription {
    align-items: center;

    svg {
      margin-top: 0;
    }
  }
}
@media (max-width: 380px) {
  div.container-toast-simples {
     width: 200px;
  }
}
</style>
