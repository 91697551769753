import Vue from "vue";
import Vuex from "vuex";

import registration from "./modules/registration.module";
import toast from "./modules/toast.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { registration, toast },
});
