<template>
  <div class="container-simples">
    <transition-group name="toast" tag="div">
      <toast-item
        v-for="message in messages"
        :key="message.id"
        :toast="message"
      />
    </transition-group>
  </div>
</template>
<script>
import ToastItem from "./ToastItem.vue";
import { mapActions, mapState } from "vuex";
export default {
  components: { ToastItem },
  computed: {
    ...mapState("toast", ["messages"]),
  },
  methods: {
    ...mapActions("toast", ["addToast", "removeToast"]),
  },
};
</script>
<style lang="scss" scoped>
div.container-simples {
  font-family: "Roboto", sans-serif;
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px 30px;
  overflow: hidden;
  overflow-y: hidden;
  z-index: 10000;
}

.toast-enter-active,
.toast-leave-active {
  transition: all 0.5s ease-out;
}

.toast-enter-active,
.toast-leave-to {
  right: -120%;
  opacity: 0;
}

.toast-enter-to,
.toast-leave {
  right: 0;
  opacity: 1;
}
</style>
