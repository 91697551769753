<template>
  <div class="background">
    <toasts></toasts>
    <div class="logo-container">
      <img class="logoIcon desktop" src="../assets/simples-logo.png" />
    </div>
    <div class="container-card">
      <img class="logoIcon mobile" src="../assets/simples-logo.png" />
      <h1 class="form-title">{{ $t("register.startNow") }}</h1>
      <h2 class="form-subtitle">
        <small>{{ $t("register.freeTrial.freeTrial") }}</small>
        <small>{{ $t("register.freeTrial.for") }}</small>
        <small>{{ $t("register.freeTrial.sevenDays") }}</small>
      </h2>
      <h3 class="form-legend">{{ $t("register.creditCard") }}</h3>
      <div class="form-card">
        <div class="card-header">
          <h1 class="header-title">{{ $t("register.createAccount") }}</h1>
          <small class="header-subtitle">{{ $t("register.startTrial") }}</small>
        </div>
        <form @submit.prevent="handleFormSubmit" class="form">
          <base-input
            :placeholder="$t('register.name')"
            icon="user"
            v-model="form.name"
            :isWrong="$v.form.name.$error"
          ></base-input>

          <base-input
            :placeholder="$t('register.email')"
            icon="envelope"
            v-model="form.email"
            :isWrong="$v.form.email.$error"
          ></base-input>

          <base-input
            :placeholder="$t('register.phone')"
            icon="mobile"
            v-model="form.phone"
            v-mask="['(##) ####-####', '(##) #####-####']"
            :isWrong="$v.form.phone.$error"
          ></base-input>

          <base-input
            :placeholder="$t('register.password')"
            icon="key"
            v-model="form.password"
            type="password"
            :isWrong="$v.form.password.$error"
          ></base-input>

          <base-input
            :placeholder="$t('register.confirmPassword')"
            icon="key"
            v-model="form.confirmPassword"
            type="password"
            :isWrong="$v.form.confirmPassword.$error"
          ></base-input>

          <div class="agreeTerm">
            <input type="checkbox" id="agree" />
            <label for="agree">
              {{ $t("register.agreeTerms") }}
              <strong>SimplesLogin</strong></label
            >
          </div>
          <base-button :loading="loading" type="submit">{{
            $t("register.startNowBtn")
          }}</base-button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import BaseInput from "@/components/BaseInput";
import BaseButton from "@/components/BaseButton.vue";
import {
  required,
  email,
  sameAs,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import Toasts from "@/components/Toast/Toasts.vue";
import errors from "@/errors/index.js";
import sleep from "@/helpers/sleep.js";

export default {
  components: { BaseInput, BaseButton, Toasts },
  name: "Register",
  data: () => ({
    form: {
      name: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
    },
    loading: false,
    postId: 0,
  }),
  mounted() {
    if (window.location.href.toString().includes("/Register/en")) {
      this.$i18n.locale = "en";
    } else if (window.location.href.toString().includes("/Register/ru")) {
      this.$i18n.locale = "ru";
    } else if (window.location.href.toString().includes("/Register/es")) {
      this.$i18n.locale = "es";
    }
  },
  methods: {
    ...mapActions("registration", ["setSuccessful"]),
    ...mapActions("toast", ["addToast"]),
    handleFormSubmit() {
      this.$v.form.$touch();
      if (
        this.$v.form.name.$invalid ||
        this.$v.form.email.$invalid ||
        this.$v.form.phone.$invalid ||
        this.$v.form.password.$invalid ||
        this.$v.form.confirmPassword.$invalid ||
        !document.getElementById("agree").checked
      ) {
        return;
      }
      this.loading = true;
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(this.form),
      };
      fetch("https://api.simpleslogin.com/api/register", requestOptions)
        .then(async (response) => {
          const data = await response.json();

          if (!response.ok) {
            this.loading = false;
            const error = data;
            return Promise.reject(error);
          }

          this.loading = false;
          this.addToast([
            {
              id: this.postId,
              type: "success",
              title: "Usuário criado",
              description: "O usuário foi criado com sucesso",
            },
          ]);
          this.postId++;
          await sleep(3000);
          this.setSuccessful(true);
          if (window.location.href.toString().includes("/Register/en")) {
            this.$router.push("/Successpage/en");
          } else if (window.location.href.toString().includes("/Register/ru")) {
            this.$router.push("/Successpage/ru");
          } else if (window.location.href.toString().includes("/Register/es")) {
            this.$router.push("/Successpage/es");
          } else {
            this.$router.push("/Successpage/");
          }
        })
        .catch((error) => {
          this.addToast([
            {
              id: this.postId,
              type: "error",
              title: "Ops!",
              description: errors[error],
            },
          ]);
          this.postId++;
        });
    },
  },
  validations: {
    form: {
      name: {
        required,
      },
      cpf: {
        required,
      },
      phone: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        minLength: minLength(6),
        maxLength: maxLength(15),
        required,
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
.background {
  width: 100vw;
  height: 100vh;
  background-color: #383464;
}
.container-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: table;
}

.form-card {
  width: 420px;
  height: 550px;
  background: rgb(255, 255, 255);
  box-shadow: 0 2px 10px #3d3d3d;
  margin-top: 43px;
  margin-left: auto;
  margin-right: auto;
  display: table;
}
.form-title {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 23pt;
  color: rgb(255, 255, 255);
  font-family: "Roboto", sans-serif;
}
.form-subtitle {
  margin-top: 0px;
  margin-bottom: 8px;
  font-size: 25pt;
  color: rgb(255, 255, 255);
  font-family: "Roboto", sans-serif;
}
.form-subtitle :nth-child(1),
.form-subtitle :nth-child(3) {
  color: #34e395;
}
.form-subtitle :nth-child(2) {
  margin: 0 6px;
}
.form-legend {
  font-size: 11pt;
  font-family: "Roboto", sans-serif;
  margin: 0;
  color: rgb(255, 255, 255);
  font-weight: 700;
}
.logo-container {
  width: 90%;
  max-width: 1850px;
  margin-left: auto;
  margin-right: auto;
}
.logoIcon {
  width: 180px;
  height: auto;
  margin-left: auto;
  margin-top: 60px;
}
.header-title {
  font-family: sans-serif;
  color: #383464;
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 0px;
}
.header-subtitle {
  font-family: sans-serif;
  color: #70706f;
  font-size: 22px;
}
.form {
  margin-top: 20px;
}
label {
  font-family: sans-serif;
  font-size: 11pt;
  color: #70706f;
  align-self: flex-start;
}
.agreeTerm {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  width: 360px;
}
.mobile {
  display: none;
}
@media (max-width: 600px) {
  .form-card {
    width: 20vw;
  }
  .container-card {
    width: 20vw;
  }
  .desktop {
    display: none;
  }
  .form-subtitle {
    margin-bottom: 18px;
  }
  .mobile {
    display: block;
    margin-bottom: 11px;
  }
  .form-title {
    font-size: 20pt;
    width: 322px;
  }
  .form-subtitle {
    font-size: 23pt;
  }
  .agreeTerm {
    width: 270px;
    font-size: 10pt;
  }
}
@media (max-width: 875px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
    margin-bottom: 11px;
  }
  .logoIcon {
    margin-right: auto;
  }
  .container-card {
    position: initial;
    top: 0;
    left: 0;
    transform: none;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .background {
    overflow-y: scroll;
  }
}
@media (max-width: 365px) {
  .agreeTerm {
    width: 280px;
  }
}
@media (max-height: 720px) {
  .container-card {
    position: initial;
    top: 0;
    left: 0;
    transform: none;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .background {
    overflow-y: scroll;
  }
}
</style>
