const state = {
  messages: [],
};
const mutations = {
  ADD_TOAST(state, data) {
    state.messages = data;
  },
  REMOVE_TOAST(state, id) {
    state.messages = state.messages.filter((message) => message.id !== id);
  },
};
const actions = {
  addToast({ commit }, data) {
    commit("ADD_TOAST", data);
  },
  removeToast({ commit }, id) {
    commit("REMOVE_TOAST", id);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
