<template>
  <div class="container" :class="{ isFocused, isFilled, isWrong }">
    <font-awesome-icon v-if="!!icon" :icon="icon" size="lg" />
    <input
      @input="$emit('input', $event.target.value)"
      :placeholder="placeholder"
      @focus="handleInputFocus"
      @blur="handleInputBlur"
      :value="value"
      :type="type"
      :name="name"
      ref="input"
    />
    <button
      type="button"
      @click.prevent.stop="showPassword = !showPassword"
      v-if="type === 'password'"
      class="password-eye"
      tabindex="-1"
    >
      <font-awesome-icon v-if="!showPassword" icon="eye" size="lg" />
      <font-awesome-icon v-else icon="eye-slash" size="lg" />
    </button>
  </div>
</template>

<script>
export default {
  name: "BaseInput",
  props: ["value", "placeholder", "type", "icon", "name", "isWrong"],
  data() {
    return {
      isFocused: false,
      isFilled: false,

      showPassword: false,
    };
  },
  methods: {
    handleInputFocus() {
      this.isFocused = true;
    },
    handleInputBlur() {
      this.isFocused = false;
      if (this.$refs.input) {
        this.isFilled = !!this.$refs.input.value;
      }
    },
    handleClickContainer() {
      this.$refs.input.focus();
    },
  },
  watch: {
    showPassword(value) {
      if (value) {
        this.$refs.input.type = "text";
      } else {
        this.$refs.input.type = "password";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  padding: 18px;
  width: 80%;

  border: 1px solid #cccac8;;
  color: #cccac8;
  display: flex;
  align-items: center;

  transition: all 0.2s;
  margin: 10px auto;

  &.isFocused {
    border-width: 1px;
    border-color: #3ddd95;
    color: #3ddd95;
  }
  &.isWrong {
    border-width: 1px;
    border-color: #c53030;
  }
  &.isFilled {
    color: #3ddd95;
  }
  input {
    background: transparent;
    flex: 1;
    border: 0;
    color: black;
    font-size: 16px;
    margin-left: 8px;
    outline: 0;

    &::placeholder {
      color: #cccac8;
    }
  }
  input:focus {
    outline-width: 0;
  }

  input::-webkit-input-placeholder {
    font-size: 16px;
  }
  button.password-eye {
    border: none;
    background: none;
    color: #cccac8;
  }

  + div {
    margin-top: 8px;
  }
}
</style>
