<template>
  <div class="container">
        <header>
            <div class="header-items">
                <img class="logo-image" src="https://cdn.discordapp.com/attachments/943490310753898507/943491004047183892/PERFIL_REDE_SOCIAIS1.png">
                <a href="https://www.simpleslogin.com/">
                    <button class="header-button">{{ $t("SuccessPage.homePage") }}</button>
                </a>
            </div>
        </header>
        <main>
            <div class="main-text">
                <p>
                    <strong class="first-redline">{{ $t("SuccessPage.Congratulations") }}</strong>
                </p>
                <p>
                   {{ $t("SuccessPage.firstRedlineOne") }} <br> {{ $t("SuccessPage.firstRedlineTwo") }}
                </p>
                <p>
                    <strong>{{ $t("SuccessPage.firstRedlineThree") }}</strong> 
                </p>
            </div>
            <div class="OS-options">
                <div class="windows-option">
                    <img class="windows-image" src="https://images.assets-landingi.com/g5bNSW4lcKrXzjH4/windows.png" alt="os-img-windows">
                    <a class="windows-download-link"  href="https://storage.simpleslogin.com/simpleslogin.exe" data-target-url="https&colon;&sol;&sol;storage&period;simpleslogin&period;com&sol;simpleslogin&period;exe">
                        <button class="windows-download-button">{{ $t("SuccessPage.downloadW") }}</button>
                    </a>
                </div>
                <div class="colum-line"></div>
                <div class="mac-option">
                    <img class="mac-image" src="https://images.assets-landingi.com/NKSYjN8iAYDHBLQD/mac.png" alt="os-img-mac">
                    <a  class="mac-download-link"  href="https://storage.simpleslogin.com/simpleslogin.dmg" data-target-url="https&colon;&sol;&sol;storage&period;simpleslogin&period;com&sol;simpleslogin&period;dmg">
                        <button class="mac-download-button">{{ $t("SuccessPage.downloadM") }}</button>
                    </a>
                </div>
            </div>
            <div class="last-info">
                <p>
                   {{ $t("SuccessPage.lastInfoOne") }} <br>{{ $t("SuccessPage.lastInfoTwo") }}
                    
                </p>
            </div>
            <div class="video">
                <img class="video-simples" src="https://cdn.discordapp.com/attachments/940934850695802900/941691839579324436/GIF_PT.gif"/>
                <img class="notebook-image" src="https://images.assets-landingi.com/lsMHSxNpykS7OGZp/MOCKUP.png" alt="">
            </div>
            <div class="img-div">
                <img class="last-img" src="https://s3zapfacil.s3.us-west-2.amazonaws.com/Assets/dev//4VRBYGZQ9helXNu.png?t=1642681554056" alt="">
            </div>
        </main>
        <footer>
            <div class="container-group-items">
                <div class="group-items">
                    <p>{{ $t("footer.help") }}</p>
                    <a class="item-group" href="https://api.whatsapp.com/send?phone=5511937065296">Whatsapp</a>
                    <a class="item-group" href="https://tawk.to/chat/5fea5820df060f156a915520/1eqlmgfvh">{{ $t("footer.onlineChat") }}</a>
                </div>
                <div  class="group-items">
                    <p>{{ $t("footer.institutional") }}</p>
                    <a class="item-group" href="https://www.simpleslogin.com/">{{ $t("footer.start") }}</a>
                    <a class="item-group" href="https://www.simpleslogin.com/download#nTKuAi1RFlqix2DP1tbMK2VCKQfdaCcf">{{ $t("footer.fingerprint") }}</a>
                    <a class="item-group" href="https://www.simpleslogin.com/download#hCw2i4Tn0Fgdt2JMg3azSMd8fhurazyw">{{ $t("footer.whoIsItFor") }}</a>
                    <a class="item-group" href="https://www.simpleslogin.com/download#kNeeELJ2sObf1Nmd883gM2nw6K3m0NOu">{{ $t("footer.plans") }}</a>
                </div>
                <div class="group-items">
                    <p>{{ $t("footer.terms") }}</p>
                    <a class="item-group" href="https://www.simpleslogin.com/termos-de-uso">{{ $t("footer.termsOfUse") }}</a>
                    <a class="item-group" href="https://www.simpleslogin.com/politicas-de-privacidade">{{ $t("footer.priacyPolicy") }}</a>
                </div>
            </div>
            <div class="footer">
               
                    <img class="footer-img" src="https://cdn.discordapp.com/attachments/943490310753898507/943491004282056735/PERFIL_REDE_SOCIAIS.png"/>
                    <span class="footer-text">&copy; &nbsp; 2020 - 2022
                        <strong>SIMPLESLOGIN TECNOLOGIA LTDA</strong>
                        CNPJ: 41.675.734/0001-58
                    </span>
                    <span class="social-links">
                        <a target="_blank" href="https://www.youtube.com/channel/UCELUjoHVafWGdgLbuZoTyzA"
                            class="social-link">
                            <i class="fa fa-youtube-play"></i>
                        </a>
                        <a target="_blank"
                            href="https://www.instagram.com/simpleslogin/" class="social-link">
                            <i class="fa fa-instagram"></i>
                        </a>
                        <a class="social-link" target="_blank"
                            href="https://www.facebook.com/SimplesLogin-Multi-Login-103345678353292">
                            <i class="fa fa-facebook-square"></i>
                        </a>
                        </span>
                
            </div>
            <div class="footer-mobile">
               <div class="row-one">
                <img class="footer-img" src="https://cdn.discordapp.com/attachments/943490310753898507/943491004282056735/PERFIL_REDE_SOCIAIS.png"/>
                <span class="social-links">
                    <a target="_blank" href="https://www.youtube.com/channel/UCELUjoHVafWGdgLbuZoTyzA"
                        class="social-link" style="margin-right: 0px;">
                        <i class="fa fa-youtube-play"></i>
                    </a>
                    <a target="_blank"
                        href="https://www.instagram.com/simpleslogin/" class="social-link">
                        <i class="fa fa-instagram"></i>
                    </a>
                    <a class="social-link" target="_blank"
                        href="https://www.facebook.com/SimplesLogin-Multi-Login-103345678353292">
                        <i class="fa fa-facebook-square"></i>
                    </a>
                    </span>
                </div>
                <div class="row-two">
                    <span class="footer-text">&copy; &nbsp; 2020 - 2022
                        <strong>SIMPLESLOGIN TECNOLOGIA LTDA</strong>
                        CNPJ: 41.675.734/0001-58
                    </span>
                </div>    
            </div>
        </footer>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "SuccessPage",
  mounted() {
     if(window.location.href.toString().includes("/SuccessPage/en")){
        this.$i18n.locale = 'en'
      }else if(window.location.href.toString().includes("/SuccessPage/ru")){
        this.$i18n.locale = 'ru'
      }else if(window.location.href.toString().includes("/SuccessPage/es")){
        this.$i18n.locale = 'es'
      }
      const getPlatform = window.navigator.platform
        if (getPlatform == "Win32" || getPlatform == "Win64") {
            window.location.href =
                "https://storage.simpleslogin.com/simpleslogin.exe"
        } else if (
            getPlatform == "MacIntel" ||
            getPlatform == "MacPPC" ||
            getPlatform == "Mac68K" ||
            getPlatform == "Macintosh"
        ) {
            window.location.href =
                "https://storage.simpleslogin.com/simpleslogin.dmg"
        }
  },
  methods: {
    ...mapActions("registration", ["setSuccessful"]),
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
body, html {
    padding: 0;
    margin: 0;
}

p {
    font-family:  sans-serif;
    font-size: 17px;
}

header {
    width: 100%;
    grid-column: 1/3;
    grid-row: 1/2;
    background-color: #40446c;
}

main {
    width: 100%;
    grid-column: 1/3;
    grid-row: 2/3;
    background-image: -webkit-linear-gradient(180deg,#f3f8ff,#e2eaf4);
}

footer {
    grid-column: 1/3;
    grid-row:3/4;
    width: 100%;
}

a {
    cursor: pointer;
}
.container{
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: 72px auto 325px;
   
}

.header-items {
    display: flex;
    max-width: 1200px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
}

.logo-image {
    width: 163px;
    height: 32px;
    height:auto;
    display:inline-block;
    margin-top: 15px;
    
}

.header-button {
    background-color: #40446c;
    height: 30px;
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
    color: #fff;
    border: 1.5px solid #1dba83;
    border-radius: 18px;
    display:inline-block;
    margin-left: auto;
    margin-top: 20px;
    font-family: 'Libre Franklin', sans-serif;
    font-weight: 900;
    line-height: 1.4;
    cursor: pointer;
}
.btn-ru {
    width: 170px !important;
}

.main-text {
    margin-top: 50px;
    text-align: center;
}

.main-text > p {
   margin: 22px;
}

.main-text p:nth-child(1) {
    max-width: 540px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 22px;
    padding-right: 22px;
    font-size: 20px;
}

.OS-options {
    display: flex;
    width: 500px;
    height: 188px;
    margin-left: auto;
    margin-right: auto;
}

.windows-option {
    width: 50%;
    height: 100%;
    display:flex;
    flex-direction: column;
}

.windows-image{
    width: 45px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 48px; 
}

.windows-download-button {
    color: #fff;
    font-family: sans-serif;
    background-color: #ff8c20;
    width: 180px;
    height: 35px;
    font-size: 11px;
    border: none;
    border-radius: 5px;
    font-weight: 900;
    margin-left: auto;
    margin-right: auto;
    margin-top: 38px;
    box-shadow: 0px 10px 16px  #a8a8a8;
    cursor: pointer;
}

.windows-download-link {
    width: 180px;
    margin-left: auto;
    margin-right: auto;
}

.mac-option {
    width: 50%;
    height: 100%;
    display:flex;
    flex-direction: column;
}

.mac-image {
    width: 45px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 39px;
}

.mac-download-button {
    color: #fff;
    font-family: sans-serif;
    background-color: #ff8c20;
    width: 180px;
    height: 35px;
    font-size: 11px;
    border: none;
    border-radius: 5px;
    font-weight: 900;
    margin-left: auto;
    margin-right: auto;
    margin-top: 37px;
    box-shadow: 0px 10px 16px  #a8a8a8;
    cursor: pointer;
}

.mac-download-link {
    width: 180px;
    margin-left: auto;
    margin-right: auto;
}

.colum-line {
    display: inline-block;
    width: 2px;
    height: 110px;
    background: rgb(221, 220, 220);
    margin-top: 43px;
}

.last-info {
    margin-top: 40px;
    text-align: center;
}

.video {
    margin-left: auto;
    margin-right: auto;
    width: 718px;
    height: 545px;
    position: relative;
    right: 14px;
}

.notebook-image {
    z-index: 0;
    top: -260px;
    left: 14px;
    position: relative;
    height: 396.297px;
    width: 718px;
}

.video-simples {
    position: relative;
    top: 74px;
    left: 93px;
    width: 562px;
    height: 316px;
    z-index: 1;
}

.img-div {
    margin-left: auto;
    margin-right: auto;
    width: 845px;

}

.container-group-items {
    margin-top: 50px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    width: 85%;
    max-width: 1200px;
}

.group-items {
    color: #8f8f95;
    text-align: center;
    font-weight: bold;
    display: flex;
    flex-direction: column;
}

.group-items > a {
    text-decoration: none;
    color: inherit;
    font-family: sans-serif;
    font-weight: 100;
    font-size: 14px;
  
}

.item-group {
    margin: 3px 0px;
    max-width: 180px;
}

.footer{ 
    margin-top: 60px;
    width: 85%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
}
.footer-mobile {
    display: none;
}

.footer-img {
    width: 117px;
    height: 27px;
}

.footer-text {
    font-family: sans-serif;
    color: #8f8f8f;
    margin: auto;
    font-size: 11px;
}

.social-links {
    width: 100px;
    display: flex;
    justify-content: space-between;
}

.social-link {
    font-size: 22px;
    color: #595e69;
    text-decoration: none;
}

@media screen and (max-width:890px) {

    .container{
        grid-template-rows: 72px 1400px 350px; 
    }

    .video {
        margin-top: 50px;
        height: 441px;
    }
    
    .notebook-image {
        left: 37px;
        height: 359.297px;
        width: 658px;
    }
    
    .video-simples {
        top: 34px;
        left: 112px;
        width: 506px;
        height: 273px;
    }

    .img-div {
        width: 753px;
    }

    .last-img {
        width: 753px;
    }

}

@media screen and (max-width:795px) {

    .container{
        grid-template-rows: 72px auto 550px; 
    }

    .main-text {
        margin-top: 50px;
    }

    .video {
       display: none;
    }

    .img-div {
        margin-top: 50px;
        width: 500px;
    }

    .last-img {
        width: 500px;
    }

    .container-group-items {
        margin-top: 50px;
        justify-content:inherit;
        flex-direction: column;
    }

    .footer{ 
        margin-top: 60px;
    }

    .item-group {
        margin: 10px; 
        max-width: inherit;
    }

    .footer{ 
        display: none;
    }

    .footer-mobile {
        display: block;
        width: 420px;
        margin-top: 50px;
        margin-left: auto;
        margin-right: auto;
    } 

    .row-one {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }  

    .row-two {
        text-align: center;
    }

}

@media screen and (max-width: 500px) {

    .container{
        grid-template-rows: 72px auto 550px; 
    }

    .logo-image {
        width: 100px;
        margin-top: 20px;
    }

    .header-button {
        width: 105px;
    }

    .main-text > p {
        font-size: 15px;
    }
     
    .main-text p:nth-child(2){
        width: 300px;
        margin-left: auto;
        margin-right: auto;
    }

    .main-text p:nth-child(3){
        width: 300px;
        margin-left: auto;
        margin-right: auto;
    }

    .OS-options {
        width: 305px;
    }

    .mac-download-link {
        width: 95%;
    }

    .mac-download-button {
        width: 100%;
        font-weight: 700;
    }

    .windows-download-link {
        width: 95%;
    }

    .windows-download-button {
        width: 100%;
        font-weight: 700;
        padding: 0;
    }

    .colum-line {
        margin-left: 2px;
        margin-right: 2px;
    }

    .img-div {
        margin-top: 20px;
        width: 300px;
    }

    .last-img {
        width: 300px;
    }

    .container-group-items {
        margin-top: 30px;
    }

    .footer-img {
        width: 106px;
        height: auto;
    }
    
    .social-link {
        font-size: 19px;
    }

    .row-one {
        margin-bottom: 10px;
    } 

    .row-two {
        width: 100%;
    }

    .footer-text {
        font-size: 10px;
    }

}

@media screen and (max-width: 380px) { 

    .footer-mobile {
        width: 300px;
    }

    .footer-text {
        font-size: 8px;
    }

}
</style>
