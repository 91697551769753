import Vue from "vue";
import App from "./App.vue";
import i18n from "../i18n"
import vuelidate from "vuelidate";
//import { VueMaskDirective } from 'v-mask']
import VueTheMask from "vue-the-mask";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEnvelope,
  faMobile,
  faUser,
  faEye,
  faEyeSlash,
  faClipboardCheck,
  faExclamationTriangle,
  faCheckCircle,
  faInfoCircle,
  faTimesCircle,
  faKey
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import router from "./router";
import store from "./store/store";

Vue.use(vuelidate);

Vue.use(VueTheMask);
//Vue.directive('mask', VueMaskDirective);

library.add(
  faEnvelope,
  faMobile,
  faUser,
  faEye,
  faEyeSlash,
  faClipboardCheck,
  faExclamationTriangle,
  faCheckCircle,
  faInfoCircle,
  faTimesCircle,
  faKey
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
  store,
  i18n,
}).$mount("#app");
