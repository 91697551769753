const state = {
  successful: false,
};
const getters = {
  successful: (state) => state.successful,
};
const mutations = {
  SET_SUCCESSFUL(state, data) {
    state.successful = data;
  },
};
const actions = {
  setSuccessful({ commit }, status) {
    commit("SET_SUCCESSFUL", status);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
