<template>
  <button @click="$emit('click')" v-bind="$props" :class="{ block }">
    <slot v-if="!loading"></slot>
    <spinner v-else />
  </button>
</template>
<script>
import Spinner from "./Spinner";
export default {
  name: "BaseButton",
  props: { loading: { type: Boolean }, block: { type: Boolean } },
  components: { Spinner },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

button {
  background: linear-gradient(90deg, #02fc96, #24bc82);
  height: 56px;
  border: 0;
  padding: 0 16px;
  color: white;
  width: 90%;
  font-weight: 700;
  margin-top: 10px;
  transition: background-color 0.2s;
  font-size: 15pt;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  margin-bottom: 20px;
  &.block {
    width: 100%;
  }

  &:hover,
  &:focus {
    outline-width: 0;
  }
}
</style>
