import Vue from "vue";
import VueRouter from "vue-router";
/* import store from "../store/modules/registration.module"; */
import Register from "../views/Register.vue";
import Register30 from "../views/Register-30.vue";
import SuccessPage from "../views/SuccessPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: {
      name: "Register",
    },
  },
  {
    path: "/Register",
    name: "Register",
    component: Register,
    alias: ['/Register','/Register/en','/Register/es','/Register/ru']
  },
  {
    path: "/Register-30",
    name: "Register-30",
    component: Register30
  },
  {
    path: "/SuccessPage",
    name: "SuccessPage",
    component: SuccessPage,
    alias: ['/SuccessPage','/SuccessPage/en','/SuccessPage/es','/SuccessPage/ru']
   /*  beforeEnter: (to, from, next) => {
      if (store.state.successful == false) {
        next("Register");
      } else {
        next();
      }
    },  */
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
